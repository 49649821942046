import { configureStore } from "@reduxjs/toolkit";
import viewProfReducer from "./viewProfSlice";
import profTypesReducer from "./profTypesSlice";
import searchDataReducer from "./searchDataSlice";

export default configureStore({
    reducer: {
        viewProf: viewProfReducer,
        profTypes: profTypesReducer,
        searchData: searchDataReducer
    }
})