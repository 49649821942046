
const commonLib = {
    getQueryParameters: function (urlQuery, name) {
        return new URLSearchParams(urlQuery).get(name);
    },
    setSessionStoarge: function (key, value) {
        sessionStorage.setItem(key, value);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    getObjFromStorage: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    calculate_age: (dob1) => {
        let today =  new Date();
        if(dob1 == null){
            return 0;
        }
        let birthOfDate = dob1.split("T")[0];
        let birthDate = new Date(birthOfDate);
        let age_now = today.getFullYear() - birthDate.getFullYear();

        return age_now;
      },
      GetQueryValues(param) {
        let url = "";
        let urlparam;
        url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
          urlparam = url[i].split('=');
          if (urlparam[0] == param) {
            return urlparam[1].split("#")[0];
          }
        }
        return "";
      },
      GetQueryDecodedValues (query,param){
        var url = "";
        url = query.slice(query.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
          var urlparam = url[i].split('=');
          if (urlparam[0] == param) {
            return urlparam[1];
          }
        }
        return "";
      },
    getSubjectData: (subjectId, responseId, memberUserId, userSubjectDataId ) => {
      return  {
       userId: memberUserId,
       userSubjectDataId: userSubjectDataId || 0,
       subjectId: subjectId,
       responseId: responseId,
     }
    },
    getContactObj: (contactType,contactName,mobilrContactId,mobileNo,loggedInUser,countryCode,emailContactId,emailId) => {
        return {
            
                ContactTypeId: contactType,
                name: contactName,
                Type: "edit",
                error: "",
                mobiles: {
                  contactId: mobilrContactId,
                  ContactTypeId: contactType,
                  mobileNo: mobileNo,
                  createdBy: loggedInUser,
                  countryCode: countryCode,
                  // mobileOther: {
                  //   othersName: this.state.emailOthersName,
                  //   othersCategoryId: 6,
                  //   isActive: true,
                  //   createdBy: this.state.loggedInUser,
                  // }
                },
    
                emailId: {
                  contactId: emailContactId,
                  ContactTypeId: contactType,
                  email: emailId,
                  createdBy: loggedInUser,
                },
              }
        },
    checkIFMinor: (dob) => {
        if (dob == null || dob == "") {
          return 0;
        }
        let dateOfBirth = new Date(dob) ;
        let month_diff = Date.now() - dateOfBirth.getTime();
        let age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        return Math.abs(year - 1970);
      },
      formatPhoneNumber: (value) => {
        if(commonLib.isNotNullUndefined(value) != true || value?.length < 10) return "";

        let str = value.slice(-10);
        let codeC = value.slice(0, -10);
        let cleaned = ("" + str).replace(/\D/g, "");

        if(codeC.startsWith("+1") || codeC.startsWith("+91")) {
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            let fornum = codeC + " " + "(" + match[1] + ") " + match[2] + "-" + match[3] + " ";
            return fornum;
          }
        } else {
          let match = cleaned.match(/^(\d{5})(\d{5})$/);
          if (match) {
            let fornum = codeC + " " + match[1] + "-" + match[2] + " ";
            return fornum;
          }
        }
        return "";
      },
      isNotNullUndefined: ( variable ) => {
        if(variable == undefined || variable == null || variable == "null" || variable == "undefined") return false;
        return true;
      },
      capitalize: (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
      },
      isUrlValid(userInput) {
        const testurl = userInput?.toLowerCase();
        console.log("abjh", userInput)
        if(!testurl) return false;
        if (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(testurl)) {
          return true;
        }
        return false;
      },
      getWebsiteLink( link ) {
        if(link?.startsWith("https://") == true || link?.startsWith("http://")) return link;
        return "http://" + link;
      },
      getProfesFulName ( profObj ) {
        if(!profObj) return "";
        return (profObj?.proFullName || "") + (profObj?.docDegree ? `, ${profObj?.docDegree?.toUpperCase()}`: '');
      },
      softCopyObj ( _object ) {
        return JSON.parse(JSON.stringify(_object));
      }
}

export default commonLib;
