export const mapApi = "https://maps.googleapis.com/maps/api/js";

export const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';
export const imagePath = [
    "icons/personalInfoHeader.svg",
    "icons/FamilyInfoHeader.svg",
    "icons/healthInfoHeader.svg",
    "icons/housingInfoHeader.svg",
    "icons/financialInfoHeader.svg",
    "icons/legalHeader.svg",
  ]



  export const illness = {
    formLabelId :[
      { id: 894, },
      { id: 895, },
      { id: 896, },
      { id: 897, },
      { id: 898, },
    ]
  }

  export const endOfLife = {
  formLabelId: [
        { id: 899, },
        { id: 900, },
        { id: 901, },
        { id: 902, },
        { id: 903, },
        { id: 904, },
    ]
  }

export const death = {
  formLabelId: [
    { id: 905, },
    { id: 906, },
    { id: 907, },
    { id: 908, },
  ]
}

export const proSerDescList = [
  {
    value: "1",
    label: 'Health',
    selectedImg: '/images/Frame-Red.png',
    unSelectedImg: '/images/Frame 1569.png',
  },
  {
    value: "2",
    label: "Housing",
    selectedImg: '/images/Property 1=Variant5.png',
    unSelectedImg: '/images/Frame 1567.png',
  },
  {
    value: "3",
    label: "Finance",
    selectedImg: '/images/Property 1=Variant3.png',
    unSelectedImg: '/images/Frame 1565.png',
  },
  {
    value: "4",
    label: "Legal",
    selectedImg: '/images/Property 1=Variant4.png',
    unSelectedImg: '/images/Frame 1566.png',
  },
  {
    value: "5",
    label: "Other",
    selectedImg: '/images/imgpsh_fullsize_anim.png',
    unSelectedImg: '/images/imgpsh_fullsize_anim (1).png',
  }
]

export const defaultMapCoordinates = {
  lat: 47.3156729,
  lng: -122.327598,
}

export const googleLibraries = ["places"];