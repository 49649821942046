import axios from "axios";
import config from "../config.json";
import { mapApi, mapKey } from "../control/Constant";
import konsole from "./konsole";
import AoUrl from "./url";
import { BASE_URL } from "./url";
import commonLib from "../control/commonLib";

// axios.defaults.timeout = 500000;
axios.defaults.baseURL =  BASE_URL;
konsole.log("config.BASE_URL",  BASE_URL);
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';

const invoke = (url, method, body, cancel, authToken) => {
  if (cancel) {
    cancel();
  }

  try {
    console.log("URL: " + url);
    console.log("method:" + method);
    console.log((method == "POST" ? "body" : "params") + JSON.stringify(body));

    return axios({
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),
      method: method,
      url: url,
      params: method === "GET" ? body : null,
      data:
        method === "POST" || method === "PUT" || method === "DELETE"
          ? body
          : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      konsole.error("Request canceled", error.message);
    } else {
      konsole.error("Something went wrong: ", error.message);
    }
  }
};

export let cancelPrevCancelableInvoke;
export function cancelableInvoke (url, method, body) {
  try {
    console.log("URL: " + url);
    console.log("method:" + method);
    console.log((method == "POST" ? "body" : "params") + JSON.stringify(body));
    
    return axios({
      cancelToken: new axios.CancelToken(function executor(c) {
        cancelPrevCancelableInvoke = c;
      }),
      method: method,
      url: url,
      params: method === "GET" ? body : null,
      data:
      method === "POST" || method === "PUT" || method === "DELETE"
      ? body
      : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      konsole.error("Request canceled in cancelableInvoke", error.message);
    } else {
      konsole.error("Something went wrong in cancelableInvoke: ", error.message);
    }
  }
};

const Services = {
  // getSubtenantDetail: async (subtenantId) => {
  //     let cancel;
  //     let url = AoUrl.getSubtenantDetailPath
  //     let body = {};
  //     body['subtenantId'] = subtenantId;

  //     return invoke(url, "POST", body, cancel);
  // },
  getProfessionalUserList: async (
    pagenum,
    SearchText,
    RowsOfPage,
    ProSerDescId,
    SearchName,
    ProTypeIdValue,
    FeeOnlyId
  ) => {
    let cancel;
    let query =
      SearchName != undefined && ProTypeIdValue != undefined
        ? "?PageNumber=" + pagenum + "&SearchText=" + SearchText + "&SearchName=" + SearchName + "&RowsOfPage=" + RowsOfPage + "&ProSerDescId=" + ProSerDescId + "&ProTypeId=" +  ProTypeIdValue : SearchName != undefined ? "?PageNumber=" +   pagenum +   "&SearchText=" +   SearchText +   "&SearchName=" +   SearchName +   "&RowsOfPage=" +   RowsOfPage +   "&ProSerDescId=" +   ProSerDescId : ProTypeIdValue != undefined ? "?PageNumber=" +   pagenum +   "&SearchText=" +   SearchText +   "&RowsOfPage=" +   RowsOfPage +   "&ProSerDescId=" +   ProSerDescId +   "&ProTypeId=" +   ProTypeIdValue : "?PageNumber=" +   pagenum +   "&SearchText=" +   SearchText +   "&RowsOfPage=" +   RowsOfPage +   "&ProSerDescId=" +   ProSerDescId;

    query =
      ProTypeIdValue == 1 && FeeOnlyId == true ? query + "&FeeOnlyId=1" : query;

    let url = AoUrl.getProfessionalUser + query;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getAddressByUserIddata: async (userId) => {
    let cancel;
    let url = AoUrl.getAddressByUserIdPath + userId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  postAddMemberById: async (userId, createdBy, proTypeId, proUserId, token) => {
    let cancel;
    let url = AoUrl.addProfessionalUserPost;
    let body = {};
    let professionalUser = {};
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    body["userId"] = userId;
    body["createdBy"] = createdBy;
    professionalUser["proTypeId"] = proTypeId;
    professionalUser["proUserId"] = proUserId;

    body["professionalUser"] = professionalUser;

    return invoke(url, "POST", body, cancel);
  },
  getProfessionalUserDetails: async (userId, pagenum) => {
    let cancel;
    let url = AoUrl.getallProfessionalUsers + userId + "&PageNumber=" + pagenum;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getSpecialtypePath: async () => {
    let cancel;
    let url = AoUrl.getSpecialtype;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  initMapScript: () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApi}?key=${mapKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  },
  getLoggedInUser: async (userId, appState, loggedInUserId, roleId) => {
    let cancel;
    let url =
      AoUrl.getAthenticatePath + `${userId}/${appState}/${loggedInUserId}/${roleId}/`;
    return invoke(url, "GET", {}, cancel);
  },
  putProfessionaluser: async (dataObj) => {
    let cancel;
    let url = AoUrl.putProfessionaluserPath;
    let body = dataObj;

    return invoke(url, "PUT", body, cancel);
  },

  upsertProfessionalUser:async(dataObj,authToken)=>{
    let cancel;
    let url = AoUrl.upsertProfessionalUserPath;
    let body = dataObj
    // axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

    return invoke(url,"POST",body,cancel)
  },
  upsertProfessionalMappingUser:async(dataObj,authToken)=>{

    let cancel;
    let url = AoUrl.upsertProfessionalMapping;
    let body = dataObj;
    // axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`
    return invoke(url,"POST",body,cancel)
  },
  addPrimaryCareMember: async (loggedInUserId, userId, professUserId, proUserId, isPrimary) => {
    let jsonObject = {
      userId: userId,
      physician: {
        "docMemberUserId": professUserId,
        "doc_License": null,
        "experience_yrs": 0,
        "is_Primary": isPrimary == true ? true : false,
        "is_Primary_Care": isPrimary == true ? true : false,
        "is_GCM": false,
        'is_GCM_Certified': false,
        "speciality_Id": null,
        "other": null,
        "isProUserAdded": true,
        "pro_User_Id": proUserId,
        "happy_With_Service": false,
        "visit_Duration": "0",
        "isSameSpecialist": false,
        "createdBy": loggedInUserId
      }
    }

    let cancel;
    let url = AoUrl.addPrimaryCareMember;
    let body = jsonObject;
    // axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`
    return invoke(url,"POST",body,cancel)
  },
  userPrimaryCareMap: async (loggedInUserId, userId, primaryCareId) => {
    let jsonObject = {
      createdBy: loggedInUserId,
      primaryCareId: primaryCareId,
      sameInsUserId: null,
      userId: userId
    }

    let cancel;
    let url = AoUrl.userPrimaryCareMap;
    let body = jsonObject;
    // axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`
    return invoke(url,"POST",body,cancel)
  },
  getProfessionalSubTypeservice:async(proTypeid)=>{
    let cancel;
    let url = AoUrl.getProfessionalSubTypeurl+"?protypeId="+proTypeid;
    let body = {}
    return invoke(url,"GET",body,cancel)
  },
  getProfessTypeId:async(proserdescId)=>{
    let cancel;
    let url = AoUrl.getProfessionalTypeurl+`?proSerDescId=${proserdescId}`;
    let body={}
    return invoke(url,"GET",body,cancel)
  },
  getMemberProfessionals:async(memberUserId, primaryUserId, authToken) => {
    let cancel;
    let url = `${AoUrl.getMemberProfessionals}?MemberUserId=${memberUserId}&primaryUserId=${primaryUserId}`;
    let body = {};
    // axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

    return invoke(url, 'GET', body, cancel);
  },
  getProfessSearchV3: async (proUserId) => {
    let cancel;
    let url = `${AoUrl.getProfessSearchV3}?ProUserId=${proUserId}`;
    let body = {};
    // let url = AoUrl.postGetProfessSearchV3;
    // let body = {
    //   ProUserId: proUserId,
    //   ProSerDescIds: "",
    //   ProTypeIds: "",
    //   ProSubTypeIds: ""
    // };
    // axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

    return invoke(url, 'GET', body, cancel);
    // return invoke(url, 'POST', body, cancel);
  },
  getAndSetAuthToken: function async (tokenKey) {
    const decodedKey = window.atob(tokenKey);
    const primaryUserId = commonLib.GetQueryDecodedValues(decodedKey, "userId") || "";
    const loggenInId = commonLib.GetQueryDecodedValues(decodedKey, "loggenInId") || "";
    const roleId = commonLib.GetQueryDecodedValues(decodedKey, "roleId") || "";
    const appState = commonLib.GetQueryDecodedValues(decodedKey, "appState") || "";

    this.getLoggedInUser(primaryUserId, appState, loggenInId, roleId)
    .then((res) => {
      const _authToken = res?.data?.data?.accessToken;
      if(_authToken) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${_authToken}`;
      }
    }).catch((err) => konsole.log("authToken fails err", err))
  },
  commonAPIInvoke: function async (method, url, body) {
    let cancel;
    return invoke(url, method, body, cancel);
  }
};

const loadAsyncScript = async (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
};

export default Services;
