import React from "react";
import Heads from "../components/Heads";
import EventMenu from "../components/EventMenu";

export default function Home() {
    return (
      <>
        {/* <Headerpage /> */}
  
        <div style={{ height: "100vh" }}>
          {/* <SeminarListingHeader /> */}
  
          <EventMenu />
          <Heads/>
          {/* <SeminarListingFooter /> */}
          {/* <Address/> */}
          {/* <Map isLoaded={isLoaded}/> */}
        </div>
      </>
    )
}