// import { useJsApiLoader, useLoadScript } from "@react-google-maps/api";
// import Map from "./components/Map";
import React, { useState,useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ViewProfile from "./pages/ViewProfile"
// import config from "./config.json";

// import Heads from "./components/Heads";

// import SeminarListingHeader from "./components/SeminarListingHeader";
// import EventMenu from "./components/EventMenu";
// import SeminarListingFooter from "./components/SeminarListingFooter";
// import { configConsumerProps } from "antd/lib/config-provider";
// import konsole from "./network/konsole";
// import { intakeBaseUrlWithoutSlash, intakeBaseUrl } from "./network/url";
// import Address from "./components/Address";
// import Headerpage from "./components/Header/Headerpage";
import 'antd/dist/antd.css';
import commonLib from "./control/commonLib";
import Services from "./network/Services";
import NotFoundPage from "./pages/NotFoundPage";
import Home from "./pages/Home";

export const UserContext = React.createContext({
  loader: false,
});

export default function App() {
  const [loader, setLoader] = useState(false);

  // ================= storing token, procategories in sessionStorage ==============================
  const tokenKey = commonLib.GetQueryValues("token");
  const proCategoriesKey = commonLib.GetQueryValues("proCategories");
  
  useEffect(() => {
    const SSTokenKey = commonLib.getSessionStoarge('tokenKey')

    if(tokenKey || proCategoriesKey) sessionStorage.clear();

    if(tokenKey) {
      commonLib.setSessionStoarge('tokenKey', tokenKey)
      Services.getAndSetAuthToken(tokenKey);
    } else if(SSTokenKey) {
      Services.getAndSetAuthToken(SSTokenKey);
    }

    if(proCategoriesKey) commonLib.setSessionStoarge('proCategories', window?.atob(proCategoriesKey))
    
    const userId =  commonLib.GetQueryValues("user") || "" ;
    const url = window.location.origin + window.location.pathname + `${userId ? `?user=${userId}` : ""}`;
    
    window.history.replaceState("", "", url);
  }, [])
  // ===============================================================================================

  return (
    <UserContext.Provider value={{ loader: loader, setLoader}}>
      <div className="overlay position-relative">
        {loader && (
          <div className="d-flex align-items-center justify-content-center position-fixed overLayLoader">
            <div className="spinner-border" role="status" aria-hidden="true"></div>
          </div>
        )}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/view" element={<ViewProfile/>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </UserContext.Provider>
  );
}
