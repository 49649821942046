import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";

export default function NotFoundPage () {
    const navigator = useNavigate();
    const redirectToHome = () => {
        message.loading('Redirecting to Home page');
        setTimeout(() => {
            message.destroy();
            navigator('/')
        }, 5000)
    };

    useEffect(() => {
        redirectToHome()
    }, [])


    return (
        <div id="div404">
            <div className="header" >
                <div className="brownBorder"></div>
                <Link to=".." >
                <img
                    src="\images\Rectangle.png"
                    className="img-fluid m-3"
                    style={{
                        height: "22px",
                    }}
                ></img>
                </Link>
            </div>
            <p>404 | This page could not be found.</p>
        </div>
    )
}