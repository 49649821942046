import React, { useEffect, useContext} from "react";
import commonLib from "../control/commonLib";
import konsole from "../network/konsole";
import Services from "../network/Services";
import { AGENT_URL } from "../network/url";
import { UserContext } from "../App";
import { intakeBaseUrl } from "../network/url";
import { Message } from "antd-icons";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useProfData } from "../reduxStore/viewProfSlice";
import { useSearchState } from "../reduxStore/searchDataSlice";

const Professionalcard = ({ item, tokenKey, disableBtn}) => {
  const { setLoader } = useContext(UserContext);
  const navigator = useNavigate();
  
  // =========== Search page states =====================================
  const [needSearch, setneedSearch] = useSearchState('needSearch');
  // =========== View Professional page states ==========================
  const [viewProfData, setviewProfData] = useProfData();
  // ====================================================================

  console.log(item, tokenKey, "itemitemitemitemitem12212")

  const formatProCategoriesSet = () => {
    let proCategoriesSet = new Set(item?.proCategories.map(proCategory => {
      return proCategory.proType +" "+ (proCategory.proSubType != null ? ` (${proCategory.proSubType}) ` : "");
    }))

    let proCategoriesList = ""
    proCategoriesSet.forEach(category => {
      proCategoriesList += category + ", "
    })

    item.proCategoriesList = proCategoriesList.substring(0, proCategoriesList.length - 2);
  }

  formatProCategoriesSet();

  const openViewPage = () => {
    setviewProfData(item);
    setneedSearch(false);
    window.scrollTo(0,1); 
    navigator(`/view?user=${item?.proUserId}`, {replace: false, target:'_self'})
  }

  konsole.log(item, tokenKey, "itemitemitemitemitem");

  return (
    // <div className="bgGray w-100 row justify-content-between">
    // <div className="w-100 p-0">
      <div id="profCard" className=" m-1 mb-3">
          <div className="leftCol d-none d-sm-block" style={{maxWidth: '175px', backgroundColor: '#dbdbdb', borderRadius: '10px'}}>
              <img className="h-100" src="images/professional1.svg" alt="professional profile page"/>
          </div>
          <div className="rightCol d-flex align-items-end flex-column" style={{width: 'calc(100% - min(175px, 25%) - 25px)'}}>
              <h3 style={{color: 'rgb(114, 12, 32)'}}>{commonLib.getProfesFulName(item)}</h3>
              <p className="col-10 text-truncate m-0 mb-1 text-start sam" title={item?.proCategoriesList} style={{fontWeight: "bold"}}>{item?.proCategoriesList}</p>
              <p className="mb-1 fs-18">
                <img
                  src="/images/ei_location.png"
                  alt="img"
                  className="sm-img"
                />{" "}
                {item?.addresses?.[0]?.addressLine1 ? item?.addresses[0]?.addressLine1 + ", " + item?.addresses[0]?.state + ", " + item?.addresses[0]?.country : <span>NA</span>}
              </p>
              <p className="mb-1 fs-18">
                <img
                  src="/images/fluent_call-20-regular (1).png"
                  alt="img"
                  className="sm-img"
                />
                {" "}{commonLib.formatPhoneNumber(item?.proPrimaryContact) ? commonLib.formatPhoneNumber(item?.proPrimaryContact) : <span>NA</span>}
              </p>
              {/* {item?.proPrimaryEmail?.endsWith("@aorg.com") ? "" : ( */}
                <div className="mt-1 mb-1 fs-18">
                  <img
                    src="/images/clarity_email-line.png"
                    alt="img"
                    className="sm-img"
                  />

                  <span
                    className="email "
                    style={{
                      wordWrap: "break-word",
                      width: "300px",
                      marginLeft: "5px",
                    }}
                  >
                    {(item?.proPrimaryEmail != null && item.proPrimaryEmail?.endsWith("@aorg.com") != true) ? item?.proPrimaryEmail : <span>NA</span>}
                  </span>
                </div>
              {/* )} */}
              <div className="addbtn col-12 m-0 d-flex mt-auto justify-content-center justify-content-sm-end">
              {
                (disableBtn == false && (tokenKey !== undefined && tokenKey !== "")) &&
                  <button className="add-to-my-team me-3" onClick={()=>addToMyTeam?.returnValuetoParent(item, setLoader)}>
                    Add to my team
                  </button>
                }
                <button className="add-to-my-team m-0" onClick={openViewPage}>
                  View Profile
                </button>
              </div>
          </div>
      </div>
    // </div>
    // </div>
  )
}

export default Professionalcard;



export const addToMyTeam = {

  tokenKey: function () {
    return commonLib.getSessionStoarge('tokenKey') || "";
  },

  decodedKey: function() { 
    return window.atob(this.tokenKey()) 
  }, 

  // setter: ( itemObj, setLoader ) => {
  //   this.item = itemObj
  //   konsole.log("wdvghvg ", this.item)
  // },

  // getter: () => {
  //   return this.item;
  // },
  
  returnValuetoParent: function (itemObj, setLoader) {
    this.item = itemObj;
    this.setLoader = setLoader;

    // alert("dab")
    const decodedKey = this.decodedKey();
    const proCategoriesDecoded = commonLib.getSessionStoarge('proCategories') || "";
    // debugger
    console.log("decodedkeysbvjh", decodedKey,proCategoriesDecoded,this.item);
    // debugger;
    const loggenInId = commonLib.GetQueryDecodedValues(decodedKey, "loggenInId") || "";
    const userId = commonLib.GetQueryDecodedValues(decodedKey, "userId") || "";
    const roleId = commonLib.GetQueryDecodedValues(decodedKey, "roleId") || "";
    const appState = commonLib.GetQueryDecodedValues(decodedKey, "appState") || "";
    const memberUserId = commonLib.GetQueryDecodedValues(decodedKey, "memberUserId") || "";
    // const redirectTo = commonLib.GetQueryDecodedValues(decodedKey, "redirectTo") || "";
    const isPrimaryCarePhysician = commonLib.GetQueryDecodedValues(proCategoriesDecoded, "isPrimaryCarePhysician") || "";
    const isLPO = commonLib.GetQueryDecodedValues(proCategoriesDecoded, "isLPO") || "";

    this.getLoggedInUser(userId, appState, loggenInId, roleId, memberUserId, isPrimaryCarePhysician, isLPO);
  },



  getLoggedInUser: function(userId, appState, loggenInId, roleId, memberUserId, isPrimaryCarePhysician, isLPO) {

    // alert("sdbjh")
    this.setLoader(true)
    // debugger
    // const promises = Services.getLoggedInUser(userId, appState, loggenInId, roleId)
    // promises.then(response => {
      // if (response) {

        // let loginDetail = {};
        // let primaryDetail = {};
        // debugger

        konsole.log("wdbsvhjbs", this)

        const dataObj = this.item.proCategories.map((e)=>{
          return {
            "userProId": 0,
            "proUserId": this.item.proUserId,
            "proCatId": e.proCatId,
            "userId": memberUserId,
            "lpoStatus": isLPO == "true" ? true : false,
            "isActive": true,
            "upsertedBy": userId
          }
        })
        
        konsole.log("responsesadasas token",this.item);
        // if (response.data !== null || response.data !== "") {
        //   const authToken = response.data.data.accessToken;
      

      const promise2 = Services.upsertProfessionalUser(dataObj)
      promise2.then((response)=>{
        konsole.log(response,"upsertProfessionalUserresponse")
        const proTypeId = response?.data?.data[0].proTypeId;
        if((isPrimaryCarePhysician == "true" && proTypeId == "10") || proTypeId == "11") {
          this.mapPrimaryProfess(response.data?.data[0], isPrimaryCarePhysician == "true" ? true : false);
        }
        else {
          this.setLoader(false)
          message.success("Successfully Saved");
          this.reDirecter();
        }
      }).catch((error)=>{
        this.setLoader(false)
        konsole.log(error,"upsertProfessionalUsererror")
      })
        // }
      // }
    // }).catch(err => {
    //   konsole.log("err", err);
    //   this.setLoader(false)
    //   commonLib.unAuthurizeAccess();
    // })
    //   .finally(() => {
    //     konsole.log("finish");
    //   })
  },

  mapPrimaryProfess: function(upsertResult, isPrimary) {
    const {createdBy, userId, professionalUserId, proUserId} = upsertResult;
    Services.addPrimaryCareMember(createdBy, userId, professionalUserId, proUserId, isPrimary)
    .then((res) => {
      if(res?.data?.data?.physicians?.length > 0) {
        const primaryCareId = res.data.data.physicians[0]?.primary_Care_Id;
        Services.userPrimaryCareMap(createdBy, userId, primaryCareId)
        .then((result) => {
          this.setLoader(false)
          // if(result?.data?.succeeded == true) reDirecter();
          message.success("Successfully Saved");
          this.reDirecter();
        })
        .catch((err) => {
          this.setLoader(false)
          konsole.log("mapPrimaryProfess2", err);
        })
      }
    })
    .catch((err) => {
      this.setLoader(false)
      konsole.log("mapPrimaryProfess1", err);
    })
  },

  reDirecter: function() {
    const tokenKey = this.tokenKey();
    const decodedKey = this.decodedKey();
    const currentUrl = window.location.href;
    
    sessionStorage.clear();
    
    window.history.pushState("", "", currentUrl);
    const redirectTo = commonLib.GetQueryDecodedValues(decodedKey, "redirectTo") || "";
    if(redirectTo) window.location.replace(`${redirectTo}/?token=${tokenKey}&aorg=true`)
    else window.location.replace(`${AGENT_URL}/?token=${tokenKey}&aorg=true`)
  }
}