import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import konsole from "../network/konsole";

// ========== creating slice =======================
const initialState = {
    profData: {},
    noOfUserUsedList: []
}

const viewProfSlice = createSlice({
    name: 'viewProf',
    initialState, 
    reducers: {
        setprofData: (state, action) => {
            state.profData = action.payload;
        },
        setnoOfUserUsedList: (state, action) => {
            state.noOfUserUsedList = action.payload;
        }
    }
})

const { setprofData, setnoOfUserUsedList } = viewProfSlice.actions;
export default viewProfSlice.reducer;


// ========= custom hooks =================
export function useProfData() {
    const stateValue = useSelector(state => state.viewProf.profData);
    const dispatch = useDispatch();
    
    function setStateValue ( newValue ) {
        const newValueObj = JSON.parse(JSON.stringify(newValue))        
        dispatch(setprofData(newValueObj))
    };

    return [stateValue, setStateValue];
}

export function useNoOfUserUsedList() {
    const stateValue = useSelector(state => state.viewProf.noOfUserUsedList);
    const dispatch = useDispatch();

    function setStateValue ( newValue ) {
        const newValueObj = JSON.parse(JSON.stringify(newValue))
        konsole.log("sdvbjkv", newValueObj)

        dispatch(setnoOfUserUsedList(newValueObj));
    };

    return [stateValue, setStateValue];
}