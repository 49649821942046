import { Autocomplete } from "@react-google-maps/api";
import { Col, Form, Input, Row } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import "./Heads.css";
import { useState } from "react";
import { mapApi } from "../control/Constant";
import konsole from "../network/konsole";
import Services from "../network/Services";
import { useGeolocated } from "react-geolocated";
import Geocode from "react-geocode";

const Address = (props) => {
  // konsole.log("pop",props?.getProUser[0]?.addresses[0]?.city,props.setGetProUser
  // )
  // const [address1, updateAddress] = useState({});
  // const buttonRef = useRef(null);
  const addressRef = useRef(null);
  // const [addressfiledErr, setAddressfieldErr] = useState(false);
  const autocompleteResult = useRef(null);
  // const [searchResult, setSearchResult] = useState("Result: none");
  // const [addressFilter, setaddressFilter] = useState();

  const { coords } = useGeolocated({ positionOptions: { enableHighAccuracy: true, }, userDecisionTimeout: 5000, });

  useEffect(() => {
    // Services.initMapScript().then(() => initAutoComplete());
    // if (props.img) {
    //   addressRef.current.value = "";
    //   addressRef.current.focus();
    // }
    if(props.getCurrentLocOnStart == true && coords?.latitude && coords?.longitude) GeocodeFunc(coords?.latitude, coords?.longitude);
    if(props.currentAddress != addressRef.current.value) addressRef.current.value = props.currentAddress || "";
  }, [props.getCurrentLocOnStart, coords, props.currentAddress]);

  
  const GeocodeFunc = (latitud, longitud) => {
    Geocode.setApiKey("AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI");
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    
    Geocode.fromLatLng(latitud, longitud)
    .then((response) => {
      const address = response.results?.[0];

      let addressObj = extractAddress(address);
      konsole.log(address, "vdsavsvdhshj", addressObj);

      // const lat = address.geometry["location"].lat;
      // const long = address.geometry["location"].lng;
      addressObj["coordinates"] = {
        lat: address.geometry.location.lat,
        lng: address.geometry.location.lng,
      };
      addressRef.current.value = address?.formatted_address || "";
      props.onFetchAddress(addressRef?.current?.value, addressObj);
      // setAddress(addressObj);
    },
    (error) => {
      konsole.error("vdsavsvdhshj", error);
    }
    );
  }
  // GeocodeFunc()

  const extractAddress = (place) => {
    const address = {
      city: "",
      state: "",
      zipcode: "",
      country: "",

      plain() {
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return city + zip + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      console.log("address compoenen", component);
      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zipcode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });

    return address;
  };

  // console.log("asp", address1);
  // const setAddress = (address) => {
    // address.address = addressRef.current.value;

    // updateAddress({
    //   city: address.city,
    //   state: address.state,
    //   zipcode: address.zipcode,
    //   country: address.country,
    // });
    // console.log("asp", address1);
    // props.setgetAdd(address)

    // props.form.setFieldsValue(address);
    // if (
    //   address.address == "" ||
    //   address.city == "" ||
    //   address.state == "" ||
    //   address.zipcode == "" ||
    //   address.country == ""
    // ) {
    //   setAddressfieldErr(true);
    // }
  // };

  const initAutoComplete = (autocomplete) => {
    // const autocomplete = new window.google.maps.places.Autocomplete(
    // );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => {
      autocompleteResult.current = autocomplete;
      // console.log("pror", autocomplete.getPlace());
    });
  };

  const onChangeAddress = () => {
    console.log("pror ", autocompleteResult.current.getPlace());
    const place = autocompleteResult.current.getPlace();
    const lat = place.geometry["location"].lat();
    const long = place.geometry["location"].lng();
    let finalData = extractAddress(place);

    konsole.log("finalDatafinalData", finalData);
    // const addressData = address1;
    console.log("prorplace", place, lat, long);
    console.log("latatat", place);
    finalData["coordinates"] = {
      lat: lat,
      lng: long,
    };
    console.log("asss", finalData?.coordinates);
    props.onFetchAddress(addressRef?.current?.value, finalData);
    // setAddress(finalData);
  };

  const handleClick = () => {
    addressRef.current.value = "";
    addressRef.current.focus();
    props.Addresfieldnull()
    props.onFetchAddress("", {});
    konsole.log("handleClick", addressRef)
  };

  return (
    <>
      <Autocomplete
        onLoad={initAutoComplete}
        onPlaceChanged={onChangeAddress}
        className="w-100 h-100"
        placeholder="Enter a Location"
      >
        <input
          className="Addressinput "
          placeholder="Enter a location"
          id="inputsearch"
          style={{ width: "100%", fontSize: "17px", border: "none" }}
          ref={addressRef}
          onChange={(e) => {
            if(e.target.value == "") handleClick()
          }}
        />
      </Autocomplete>
      {addressRef?.current?.value?.length > 0 && (
        <img
          src="/images/closebutton.png"
          className="mt-md-3 mt-2 mt-sm-3 me-1"
          width="25px"
          height="25px"
          style={{
            cursor: "pointer",
          }}
          onClick={handleClick}
        />
      )}
    </>
  );
};

export default Address;
