import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import konsole from "../network/konsole";

const initialState = {
    proTypeListObj: {},
    proSubTypeListObj: {},
}

const profTypesSlice = createSlice({
    name: 'profTypes',
    initialState,
    reducers: {
        setproTypeListObj: (state, action) => {
            state.proTypeListObj[action.payload.key] = action.payload.value;
        },
        setproSubTypeListObj: (state, action) => {
            state.proSubTypeListObj = action.payload;
        }
    }

})

const { setproTypeListObj, setproSubTypeListObj } = profTypesSlice.actions;
export default profTypesSlice.reducer;

export const useProTypes = () => {
    const stateValue = useSelector(state => state.profTypes.proTypeListObj);
    const dispatch = useDispatch();
    
    function setStateValue ( newValue ) {
        dispatch(setproTypeListObj(newValue))
    };

    return [stateValue, setStateValue];
}

export const useProSubTypes = () => {
    const stateValue = useSelector(state => state.profTypes.proSubTypeListObj);
    const dispatch = useDispatch();
    
    function setStateValue ( newValue ) {
        dispatch(setproSubTypeListObj(newValue))
    };

    return [stateValue, setStateValue];
}