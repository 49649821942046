import { createSlice } from "@reduxjs/toolkit";
import { defaultMapCoordinates } from "../control/Constant";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
    centerLocation: defaultMapCoordinates,
    selectLiveAddress: false,
    selectedAddressLineText: "",
    selectedAddress: {},

    proSerDescIdSelected: ['1'],
    proTypeSelected: [],
    proSubTypeSelected: [],
    
    forBoardCertified: false,

    lastSearchText: "",
    curSearchText: "",

    currentPageNo: 0,

    totalResult: {},

    needSearch: true,
}

const searchDataSlice = createSlice({
    name: "searchData",
    initialState,
    reducers: {
        // setcenterLocation: (state, action) => {
        //     state.centerLocation = action.payload;
        // },
        // setselectLiveAddress: (state, action) => {
        //     state.selectLiveAddress = action.payload;
        // },
        // setselectedAddressLineText: (state, action) => {
        //     state.selectedAddressLineText = action.payload;
        // },
        // setselectedAddress: (state, action) => {
        //     state.selectedAddress = action.payload;
        // },
        // setproSerDescIdSelected: (state, action) => {
        //     state.proSerDescIdSelected = action.payload;
        // },
        // setproTypeSelected: (state, action) => {
        //     state.proTypeSelected = action.payload;
        // },
        // setproSubTypeSelected: (state, action) => {
        //     state.proSubTypeSelected = action.payload;
        // },
        // setlastSearchText: (state, action) => {
        //     state.lastSearchText = action.payload;
        // },
        // setcurSearchText: (state, action) => {
        //     state.curSearchText = action.payload;
        // },
        // setcurrentPageNo: (state, action) => {
        //     state.currentPageNo = action.payload;
        // },
        // settotalResult: (state, action) => {
        //     state.totalResult = action.payload;
        // },
        setSearchState: (state, action) => {
            state.needSearch = true
            state[action.payload.stateName] = action.payload.stateValue;
        }
    }
})

const { setSearchState } = searchDataSlice.actions;
export default searchDataSlice.reducer;

export const useSearchState = ( stateName ) => {
    const stateValue = useSelector(state => state.searchData[stateName]);
    const dispatch = useDispatch();
    
    function setStateValue ( newValue ) {    
        dispatch(setSearchState({
            stateName,
            stateValue: newValue
        }))
    };

    return [stateValue, setStateValue];
}