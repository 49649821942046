import config from '../config.json'
// export const BASE_URL = (config.demo)? "https://aointakeuatapi.azurewebsites.net": "https://aointakeapi.azurewebsites.net";
// export const AGENT_URL= (config?.demo) ?"https://aoagentuat.azurewebsites.net":"https://aoagentdev.azurewebsites.net";
// export const AOLOGIN_URL=(config?.demo)?'https://aologinuat.azurewebsites.net':'https://aologin.azurewebsites.net';
// export const intakeBaseUrl =(config?.demo)? 'https://aointakeformuat.azurewebsites.net/' : 'https://aointakeform.azurewebsites.net/';
// export const intakeBaseUrlWithoutSlash =(config?.demo)? 'https://aointakeformuat.azurewebsites.net' : 'https://aointakeform.azurewebsites.net';
export const BASE_URL = (config.demo)? "https://aointakeuatapi.agingoptions.com": "https://aointakeapi.azurewebsites.net";
export const AGENT_URL= (config?.demo) ?"https://aoagentuat.agingoptions.com":"https://agent.agingoptions.com";
export const AOLOGIN_URL=(config?.demo)?'https://aologinuat.agingoptions.com':'https://Unilogin.agingoptions.com';
export const intakeBaseUrl =(config?.demo)? 'https://aointakeformuat.agingoptions.com/' : 'https://member.intake.agingoptions.com/';
export const intakeBaseUrlWithoutSlash =(config?.demo)? 'https://aointakeformuat.agingoptions.com' : 'https://member.intake.agingoptions.com';

let AoUrl = {
    getProfessionalUser: '/api/2/ProfessionalUser/getProfessionalUsers',
    getAddressByUserIdPath: 'api/Address/get-address-by-userid/',
    addProfessionalUserPost: 'api/ProfessionalUser/add-ProfessionalUser/',
    getallProfessionalUsers: 'api/Member/searchProfessionals?ProSerDescId=',
    getSpecialtype:'/api/SpecialityType',
    postaddprofessionluserMappingPath: "/api/ProfessionalUser/add-ProfessionalUserMapping",
    getAthenticatePath: '/api/User/AuthenticateLoggedInUser/',
    putProfessionaluserPath: "api/ProfessionalUser/update-ProfessionalUser",
    upsertProfessionalUserPath:"api/2/ProfessionalUser/upsert-ProfessionalUser",
    upsertProfessionalMapping:'/api/2/ProfessionalUser/upsert-ProfessionalUserMapping',
    addPrimaryCareMember: '/api/PrimaryCare/add-primarycaremember',
    userPrimaryCareMap: '/api/UserPrimaryCareMap',
    getProfessionalSubTypeurl:'/api/ProfessionalSubType',
    getProfessionalTypeurl:'/api/ProfessionalType',
    getMemberProfessionals:'/api/2/ProfessionalUser/getMemberProfessionals',
    getProfessSearchV3: '/api/2/ProfessionalUser/getProfessionalUsersV3',
    postGetProfessSearchV3: '/api/2/ProfessionalUser/getProfessionalUserV3',
    getOtherTypeValues: '/api/MemberOthersMap/get-member-othersmap',
    getProfessionalMemberCounts: '/api/2/ProfessionalUser/GetProfessionalCountByProUserId',
}

export default AoUrl;