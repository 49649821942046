import React, { useContext, useEffect, useRef, useState } from "react"
import konsole from "../network/konsole";
import commonLib from "../control/commonLib";
import Services from "../network/Services";
import { Link } from "react-router-dom";
// import GoogleMapReact from 'google-map-react';
// import { GoogleMap, LoadScriptNext, MarkerF } from "@react-google-maps/api";
import { defaultMapCoordinates, mapKey } from "../control/Constant";
import { addToMyTeam } from "../components/Professionalcard";
import { UserContext } from "../App";
import AoUrl from "../network/url";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import NotFoundPage from "./NotFoundPage";
import { APIProvider, AdvancedMarker, Map, useMap } from "@vis.gl/react-google-maps";
import { useNoOfUserUsedList, useProfData } from "../reduxStore/viewProfSlice";


const convertAddiDtls2List = (rawString, startBracket, endBracket, callBack) => {
    if(!rawString) return;
    let finalArray = [];
    let eleString = "";
    let addEle = false;

    for(let i = 0; i < rawString.length; i++) {
        const ele = rawString[i];
        if(ele == startBracket) addEle = true;
        else if(ele == endBracket) {
            finalArray.push(eleString);
            eleString = "";
            addEle = false
        } else if(addEle == true) eleString += ele;
    }

    konsole.log("avjhavdj", finalArray);

    if(typeof callBack == "function") callBack(finalArray);
    return finalArray;
}

const RenderBoardDetails = ({boardString}) => {
    const subStart = boardString?.indexOf("(");
    const mainCnd = (subStart && subStart != -1) ? true : false;
    const mainString = mainCnd ? boardString?.substring(0, subStart) : boardString;
    let subStringList = [];

    if(mainCnd) {
        let bracketCount = 0;
        let tempSubString = "";
        for(let i = subStart + 1; i < boardString.length - 1; i++) { // looping btw 1st '(' and last ')'
            const curChar = boardString[i];
            const next2Char = (boardString?.[i + 1] ?? "") + (boardString?.[i + 2] ?? "");

            if(bracketCount == 0 && curChar == "/") continue;
            tempSubString += curChar;

            if(curChar == "(") {
                bracketCount++;
            } else if(curChar == ")") {
                bracketCount--;
            }

            if(bracketCount == 0 && next2Char == "//") {
                subStringList.push(tempSubString);
                tempSubString = "";
            }

        }

        if(tempSubString) subStringList.push(tempSubString);
    }

    console.log("sbdvhj", boardString, mainString , subStringList)

    return (<>
        <p className="mb-0">{mainString}</p>
        <ul>
        {subStringList?.map(ele => {
            const subSubStart = ele.indexOf("(");
            const subSubEnd = ele.indexOf(")");
    
            if(subSubStart && subSubStart != -1) return <li>{ele?.substring(0, subSubStart) + ` - ` + ele?.substring(subSubStart + 1, subSubEnd)?.replaceAll(" //", ",")}</li>
            return <li>{ele}</li>
        })}
        </ul>
    </>)
}

const ViewProfile = () => {
    const [profData, setprofData] = useProfData();
    const [noOfUserUsedList, setnoOfUserUsedList] = useNoOfUserUsedList();
    const [profTypes, setprofTypes] = useState([]);
    const [profAdditionalDetails, setprofAdditionalDetails] = useState({});
    const [isTypeBusiness, setisTypeBusiness] = useState(true);
    const [otherBusinessType, setotherBusinessType] = useState("");
    const [disableBtn, setdisableBtn] = useState(true);
    const [showModal, setshowModal] = useState(false);
    const [profAdress, setprofAdress] = useState({
        centerPoint: {
            lat:  47.3156729,
            lng: -122.327598
        }
    });
    const profSelectRef = useRef(null);
    const [showMore, setshowMore] = useState(false);
    const describtionMaxLength = 10000; // 90 * 3 value & to disable show more and show less => just assign high value like 10000 

    const [proUserId, setproUserId] = useState(commonLib.GetQueryValues("user") || "");
    const currentYear = new Date().getFullYear();
    const currentAge = commonLib.isNotNullUndefined(profData?.dob) ? currentYear - profData?.dob?.slice(0, 4) : "";

    // ========== logic for add to my team =============
    const { setLoader } = useContext(UserContext);

    const getPrevProffMembers = ( profUserId ) => {
        const tokenKey = commonLib.getSessionStoarge('tokenKey');
        const decodedtokenKey = window.atob(tokenKey);
        const memberUserId = commonLib.GetQueryDecodedValues(decodedtokenKey, "memberUserId") || "";
        const primaryUserId = commonLib.GetQueryDecodedValues(decodedtokenKey, "userId") || "";

        konsole.log(`fetchingPrevProffFor memberid ${memberUserId} and primaryUserId ${primaryUserId} - profis ${profUserId}`);
        if(!memberUserId || !primaryUserId) return;

        Services.getMemberProfessionals(memberUserId, primaryUserId)
        .then((res) => {
          const result = res?.data?.data;
          let allProfMemberUserIds = result.length > 0 ? result?.map((proff) => proff.professionalUserId) : [];
          konsole.log("hgchgc", allProfMemberUserIds, profUserId, allProfMemberUserIds?.every(profId => profId != profUserId))
          if(allProfMemberUserIds?.every(profId => profId != profUserId) == true) {
            setdisableBtn(false);
          } else {
            setdisableBtn(true);
          }
        })
        .catch((err) => {
          setdisableBtn(false);
          konsole.log("fetchingPrevProffFor Error", err);
        })
    }

    useEffect(() => {
        konsole.log("sbdbvsdk", proUserId, profData.proUserId)
        if(proUserId != profData.proUserId) {
            setprofData({});
            setnoOfUserUsedList([]);
        } else {
            formateProfDetails(profData)
        }
        getProfesAllDetails();
        getProfessionalClientCounts();

        // for reseting page scroll to top
        window.onload = () => {
            window.scrollTo(0,1)
        }
    }, [])

    // useEffect(() => {
    //     konsole.log("dsvbhj", profAdress)
    // }, [profAdress])

    const getProfesAllDetails = () => {
        if(!proUserId) return 

        Services.getProfessSearchV3(proUserId)
        .then(res => {
            const _profData = res?.data?.data?.[0];
            konsole.log(_profData, "fsbvhs");
            if(!_profData) throw new Error("prof data not found")
            setprofData(_profData)
            formateProfDetails(_profData)
        }).catch(err => {
            setprofData({})
            setproUserId("");
            konsole.log(err, "fsbvhs")
        })
    }

    const getProfessionalClientCounts = () => {
        if(!proUserId) return 

        Services.commonAPIInvoke("GET", AoUrl.getProfessionalMemberCounts + `?ProUserId=${proUserId}`, {})
        .then(res => {
            konsole.log("dfnbjdb", res)
            setnoOfUserUsedList(res?.data?.data || []);
        }).catch(err => {
            setnoOfUserUsedList([]);
            konsole.log("nsvj", err);
        })
    }

    const formateProfDetails = ( _profData ) => {
        getPrevProffMembers(_profData?.userId);

        if(_profData?.businessType == "Other") getOtherBusinessTypeValue(_profData);

        filterProfType(_profData);

        const primaryAddress = filterAddress(_profData);
        setprofAdress(primaryAddress)

        convertAddiDtls2List(_profData.board, "[", "]", ( _list ) => setprofAdditionalDetails(oldSate => ({...oldSate, board: _list})));
        setprofAdditionalDetails(oldSate => ({...oldSate, languages: _profData.languages}));
        convertAddiDtls2List(_profData.award, "[", "]", ( _list ) => setprofAdditionalDetails(oldSate => ({...oldSate, award: _list})));
        convertAddiDtls2List(_profData.education, "[", "]", ( _list ) => setprofAdditionalDetails(oldSate => ({...oldSate, education: _list})));
    }

    const filterProfType = (_profData) => {
        _profData = JSON.parse(JSON.stringify(_profData));

        // business / clinic text logic =====================
        const _isTypeBusiness = _profData?.proCategories?.some(ele => ele?.proSerDescId != '1');
        setisTypeBusiness(_isTypeBusiness);

        // procategories filtering ==========================
        let hashMap = {}, final ;
        for(let eleIndex in _profData?.proCategories) {
            const eleObj =  _profData?.proCategories[eleIndex];
            eleObj["proTypeName"] = eleObj?.proType + (eleObj?.proSubType ? ` (${eleObj?.proSubType})` : "");

            hashMap[eleObj.proTypeName] = eleObj;
        }
        const finalProfList = Object.entries(hashMap)?.map(ele => ele[1]);
        konsole.log("dsbvjhsb", finalProfList)

        // const proTypesSet = new Set(_profData?.proCategories?.map(profType => profType?.proType + (profType?.proSubType ? ` (${profType?.proSubType})` : "")) || []); 
        // const proTypeList = Array.from(proTypesSet);
        setprofTypes(finalProfList);
    }

    const filterAddress = (_profData) => {
        const _primaryAddress = _profData?.addresses?.filter(ele => ele.addressTypeId == '1')?.[0];
        const lat = parseFloat(_primaryAddress?.lattitude) || 0, lng = parseFloat(_primaryAddress?.longitude) || 0;
        const primaryShortAddress = {
            city: removeStringText(_primaryAddress?.city),
            state: removeStringText(_primaryAddress?.state),
            country: removeStringText(_primaryAddress?.country),
        }
        const shortAdd = ((primaryShortAddress?.city && (primaryShortAddress?.city != primaryShortAddress?.state)) ? (primaryShortAddress?.city + ", ") : "") + (primaryShortAddress?.state ? primaryShortAddress?.state + ", " + primaryShortAddress?.country : "");

        const addressObj = {
            ..._primaryAddress,
            short: shortAdd,
            // short: (primaryShortAddress?.city && (primaryShortAddress?.city != primaryShortAddress?.state)) ? (primaryShortAddress?.city + ", " + primaryShortAddress?.state + ", " + primaryShortAddress?.country) : "",
            // long: _primaryAddress?.addressLine1 + ", " + _primaryAddress?.city + ", " + _primaryAddress?.state + ", " + _primaryAddress?.country + ", " + _primaryAddress?.zipcode,
            centerPoint: (lat && lng) ? {lat: lat, lng: lng} : undefined,
        }
        return addressObj;
    }

    const filterDescription = ( cropText ) => {
        konsole.log("vdhbahabcjh")
        let finalPara = "";
        if(profData?.biography?.length && (/[a-zA-Z]+/.test(profData?.biography) == true)) finalPara = profData?.biography;
        else {
            finalPara = `
            ${commonLib.getProfesFulName(profData)} is a ${profTypes?.map(ele => ele.proTypeName)?.join(", ")}${profAdress?.short?.length ? ` in ${profAdress?.short}` : ""}. ${profData?.businessName ? `He/She is affiliated with ${profData?.businessName}` : ""}
            `
        }
        return cropText == true && finalPara?.length > describtionMaxLength? finalPara?.substring(0, describtionMaxLength) + "..." : finalPara;
    }

    const getOtherBusinessTypeValue = ( _profData ) => {
        const payload = [{
            userId: _profData?.userId,
            isActive: true,
            othersMapNatureId: _profData?.proUserId,
            othersMapNature: ""
        }]
        Services.commonAPIInvoke('POST', AoUrl?.getOtherTypeValues , payload)
        .then(res => {
            setotherBusinessType(res?.data?.data?.[0]?.othersName || "")
        }).catch(err => {
            konsole.log(err, "fdbd")
        })
    }

    const scrollToDivById = ( id ) => {
        document.getElementById(id).scrollIntoView(true);
    }

    const removeStringText = ( value ) => {
        return value == "string" ? "" : value;
    }

    const handleAddToMyTeam = () => {
        if(showModal == false) {
            // konsole.log("dcbhsbhj", profData?.proCategories, profTypes)
            if(profTypes?.length != 1) {
                setshowModal(true)
            } else {
                addToMyTeam?.returnValuetoParent({...profData, proCategories: profTypes}, setLoader)
            }
            return
        };
        let profCheckedList = []
        profSelectRef?.current?.querySelectorAll('input[type=checkbox]:checked')?.forEach(ele => profCheckedList.push({proCatId: ele?.value}));
        konsole.log("sfbhvbs", profCheckedList);
        if(!profCheckedList?.length) {
            message.warning('Please select atleast one professional type');
            return
        }
        const finalItemObj = {...profData, proCategories: profCheckedList}
        konsole.log("bsvjh", finalItemObj)
        // return
        addToMyTeam?.returnValuetoParent(finalItemObj, setLoader)
        // setshowModal(false)
    }

    const frstCommaIndex = profAdress?.addressLine1 ? profAdress?.addressLine1?.indexOf(",") : null;
    const Street1 = frstCommaIndex > 0 ? profAdress?.addressLine1?.substring(0, frstCommaIndex) : profAdress?.addressLine1;
    const Street2 = frstCommaIndex > 0 ? profAdress?.addressLine1?.substring(frstCommaIndex + 1) : "";

    const profMobileNum = commonLib.formatPhoneNumber(profData?.proPrimaryContact) ? commonLib.formatPhoneNumber(profData?.proPrimaryContact) : "Not provided";
    const describtion = filterDescription();
    const cndDescribtion = filterDescription(showMore != true);
    const currentCenter = profAdress?.centerPoint

    return (
        <>
        {(!proUserId) ? <NotFoundPage /> : 
        <div id="profPage" className="placeholder-glow">
            <Modal
                show={showModal}
            >
                <div id="viewProfModal">
                    <h5>Please Select professional type that you prefer to add</h5>
                    <div style={{maxHeight: '40vh', overflowY: 'auto'}} ref={profSelectRef}>
                    {profTypes?.map(ele => {
                        return (<div className="form-check " key={ele.proCatId}>
                                    <input type="checkbox" value={ele.proCatId} defaultChecked={false}/>
                                    <label className="form-check-label" for="inlineCheckbox1">{ele.proType}{ele?.proSubType ? ` (${ele?.proSubType})` : ""}</label>
                                </div>)
                    })} 
                    </div>
                    <div className="d-flex justify-content-between">
                        <button className="add-to-team-btn mt-3" onClick={() => setshowModal(false)}>Cancel</button>
                        <button className="add-to-team-btn mt-3" onClick={() => handleAddToMyTeam()}>Submit</button>
                    </div>
                </div>
            </Modal>
            {/* header */}
            <div className="header" >
                <div className="brownBorder"></div>
                <Link to=".." >
                <img
                    src="\images\Rectangle.png"
                    className="img-fluid m-3"
                    style={{
                        height: "22px",
                    }}
                    // onClick={() => navigator}
                ></img>
                </Link>
            </div>

            {/* body */}
                <div className="bgGray row justify-content-between">
                    <div className="col-md-9 p-0">
                        <div id="overview" className="flex-wrap flex-sm-nowrap">
                            <div className="leftCol">
                                <img src="images/professional1.svg" alt="professional profile page"/>
                            </div>
                            <div className={`rightCol ${disableBtn == false && 'mb-5'} `}>
                            <nav aria-label="breadcrumb" className="">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to=".." >Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{commonLib.getProfesFulName(profData) || "Profile View"}</li>
                                </ol>
                            </nav>
                            {profData?.proFullName ? 
                            <div>
                                <h1 style={{color: 'rgb(114, 12, 32)'}}>{commonLib.getProfesFulName(profData)}</h1>
                                <p className="col-10 text-truncate mb-1" title={profTypes?.map(ele => ele.proTypeName)?.join(", ")} style={{fontWeight: "700"}}>{profTypes?.map(ele => ele.proTypeName)?.join(", ")}</p>
                                <p className="mb-1">{profData?.gender}{currentAge ? ((profData?.gender ? ', ' : '') + 'Age ' + currentAge) : ""}</p>
                                <p>
                                    {cndDescribtion}
                                    {describtion?.length > describtionMaxLength ? <span className="" style={{color: 'rgb(114, 12, 32)', cursor: 'pointer', float: 'right'}} onClick={() => setshowMore(!showMore)}>{showMore != true ? "Show More" : "Show Less"}</span> : ""}
                                </p>
                                {disableBtn == false && <button className="add-to-team-btn mt-3 float-end" onClick={()=> {
                                    handleAddToMyTeam()
                                }}>
                                    Add to my team
                                </button>}
                            </div>
                            :
                            <div>
                                <h1 className="placeholder col-7"></h1>
                                <br/>
                                <p className="placeholder col-5"></p>
                                <p className="placeholder col-12"></p>
                                <p className="placeholder col-5"></p>
                            </div>}
                            <div id="about"></div> {/* ======== only used for bookmark purpose =========*/}
                            </div>
                        </div>
                    </div>
                    <div className="col p-0 d-none d-md-block sideDiv">
                        {/* menu bar */}
                        <div className="navBarGrp">
                            <p onClick={() => scrollToDivById("profPage")}>Overview</p>
                            <div></div>
                            <p onClick={() => scrollToDivById("about")}>About</p>
                            <div></div>
                            <p onClick={() => scrollToDivById("description")}>Description</p>
                            <div></div>
                            <p onClick={() => scrollToDivById("contact")}>Contact</p>
                            <div></div>
                            <p onClick={() => scrollToDivById("address")}>Address</p>
                        </div>

                        {/* total no. of user card */}
                        <div className="totalUsers">
                        <div className="totalUsers-Container">
                            <div className="position-relative" style={{marginTop: `${80 * 12 / 80}%`}}>
                                <img src="/images/new3person.svg" alt="3 users icon" />
                            </div>
                            {profTypes?.map(profTypeOBJ => {
                                const userCount = noOfUserUsedList?.find(ele => ele.proCatId == profTypeOBJ?.proCatId)?.noOfUserUsed || 0;
                                konsole.log("sdbvhjws", profTypeOBJ)
                                return(
                                    <div className="" key={profTypeOBJ?.proTypeName}>
                                        <h4 className="m-0">{userCount}</h4>
                                        {`Client${userCount > 1 ? 's' : ''} ${profTypeOBJ?.proSerDescId == '1' ? "consulted" : "hired"} as ${profTypeOBJ?.proTypeName}`}
                                        <div className="my-2 w-50" style={{borderBottom: '2px solid var(--grey)', margin: 'auto'}}></div>
                                    </div>
                                )
                            })}
                            <p className="" style={{fontSize: '12px'}}>*Clients are verified by Aging Options</p>
                            {disableBtn == false && <>
                            <div className="my-2 w-50" style={{borderBottom: 'inherit', margin: 'auto'}}></div>
                            <button className="float-middle" onClick={()=> {
                                    handleAddToMyTeam()
                                }}>
                                    Add to my team
                            </button>
                            </>}
                        </div>
                        </div>
                    </div>
                </div>
            <div className="row justify-content-between" style={{margin: '0 5%'}}>
                <div className="col-md-9 p-0">
                        <div id="ProfBody">                  
                            <div className="redBorderDiv">
                                <div className="subTitle">
                                    About
                                    <div className="custom-border"></div>
                                </div>
                                <div className="mt-1">
                                    <h5>Biography</h5>
                                    {profData?.proFullName ? <p>{describtion}</p>
                                    : <>
                                        <p className="placeholder col-5"></p>
                                        <br/>
                                        <p className="placeholder col-5"></p>
                                    </>}
                                </div>
                                <div className="mt-2">
                                    <h5>Professional Categories</h5>
                                    {profData?.proFullName ? <ul className="bold-1 mb-1">
                                    {profTypes?.map(ele => {
                                        return (
                                            <li key={ele.proTypeName}>{ele.proTypeName}</li>
                                            )
                                        })
                                    }
                                    </ul>
                                    : <>
                                        <p className="placeholder col-5"></p>
                                        <br/>
                                        <p className="placeholder col-5"></p>
                                        <br/>
                                        <p className="placeholder col-5"></p>
                                    </>}
                                    <div id="description"></div> {/* ======== only used for bookmark purpose =========*/}
                                    <p>*AgingOptions does not verify qualifications for professional categories. Please verify your provider's specialty and qualifications directly with your provider and applicable medical board.</p>
                                </div>
                                {profData?.gender ? <div className="mt-2">
                                    <h5>Gender</h5>
                                    <p>{profData.gender}</p>
                                </div> : ""}
                                {currentAge ? <div className="mt-2">
                                    <h5>Age</h5>
                                    <p>{currentAge}</p>
                                </div> : ""}
                                <div className="mt-2">
                                    <h5>Languages</h5>
                                    {profData?.proFullName ? <>{profAdditionalDetails?.languages?.length ? <p>{profAdditionalDetails?.languages}</p> : <p className="ps-3">-</p>}</> : <p className="placeholder col-5"></p>}
                                </div>
                                <div className="mt-2">
                                    <h5>Awards</h5>
                                    {profData?.proFullName ? <>{profAdditionalDetails?.award?.length ? <ul>{profAdditionalDetails?.award?.map(award => <li>{award}</li>)}</ul> : <p className="ps-3">-</p>}</> : <p className="placeholder col-5"></p>}
                                </div>
                                <div className="mt-2">
                                    <h5>Education</h5>
                                    {profData?.proFullName ? <>{profAdditionalDetails?.education?.length ? <>{profAdditionalDetails?.education?.map(education => {
                                        return (<div className="me-2 mb-2">
                                            <p className="m-0">{education.split("(")?.[0]}</p>
                                            <p className="m-0 text-secondary">{education.split("(")?.[1]?.slice(0, -1)}</p>
                                        </div>)
                                    })}</> : <p className="ps-3">-</p>}</> : <p className="placeholder col-5"></p>}
                                </div>
                            </div>
                            <div className="redBorderDiv">
                                <div className="subTitle">
                                    Description
                                    <div className="custom-border"></div>
                                </div>
                                <div className="mt-2">  
                                    <h5>Board Certifications</h5>
                                    {profData?.proFullName ? <>{profAdditionalDetails?.board?.length ? profAdditionalDetails?.board?.map(ele => {
                                        return <RenderBoardDetails  boardString={ele}/>
                                    }) : <p className="ps-3">-</p>}</> : <p className="placeholder col-3 "></p>}
                                </div>
                                <div className="mt-2">
                                    <h5>{isTypeBusiness == true ? "Business" : "Clinic"} Details</h5>
                                    <SimpleLabelValue show={profData?.proFullName} label={(isTypeBusiness == true ? "Business" : "Clinic") + " Name"} value={profData?.businessName} />
                                    <SimpleLabelValue show={profData?.proFullName} label={(isTypeBusiness == true ? "Business" : "Clinic") + " Type"} value={(profData?.businessType == "Other" && otherBusinessType) ? `Other(${otherBusinessType})` : profData?.businessType} />
                                    <div id="contact"></div> {/* ======== only used for bookmark purpose =========*/}
                                    <SimpleLabelValue show={profData?.proFullName} label="Website Link" value={profData?.websiteLink} isLink={commonLib.isUrlValid(profData.websiteLink) == true} />
                                </div>
                            </div>
                            <div className="redBorderDiv">
                                <div className="subTitle">
                                    Contact
                                    <div className="custom-border"></div>
                                </div>
                                <div className="mt-1">
                                    <div className="d-flex py-2">
                                        {/* <a href={`tel:${profData?.proPrimaryContact}`}> */}
                                        <img src="\images\fluent_call-20-regular.png" alt="phone" className="smallIcon me-2"/>
                                        {profData?.proFullName ? (profMobileNum != "Not provided" ? <p className="h6 m-0">{profMobileNum}</p> : <p className="m-0 ps-3">-</p>)
                                        :<p className="placeholder col-5 m-0"></p>}
                                        {/* </a> */}
                                    </div>
                                    <div id="address" ></div> {/* ======== only used for bookmark purpose =========*/}
                                    <div className="d-flex py-2">
                                        {/* <a href={`mailTo:${profData?.proPrimaryEmail}`}> */}
                                        <img src="\images\clarity_email-line.png" alt="email" className="smallIcon me-2"/>
                                        {profData?.proFullName ? ((commonLib.isNotNullUndefined(profData?.proPrimaryEmail) && profData?.proPrimaryEmail?.endsWith("@aorg.com") != true) ? <p className="h6 m-0">{profData?.proPrimaryEmail}</p> : <p className="m-0 ps-3">-</p>)
                                        : <p className="placeholder col-5 m-0"></p>}
                                        {/* </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="redBorderDiv">
                                <div className="subTitle">
                                    Address
                                    <div className="custom-border"></div>
                                </div>
                                <div className="d-flex flex-wrap mt-2 child-min-w-30 gap-2">
                                    <SimpleLabelValue show={profData?.proFullName} label="Suite No." value={removeStringText(profAdress?.addressLine2)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="Street line 1" value={removeStringText(Street1)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="Street line 2" value={removeStringText(Street2)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="City" value={removeStringText(profAdress?.city)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="State" value={removeStringText(profAdress?.state)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="County" value={removeStringText(profAdress?.county)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="County Reference" value={removeStringText(profAdress?.countyRef)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="Country" value={removeStringText(profAdress?.country)} />
                                    <SimpleLabelValue show={profData?.proFullName} label="Zip Code" value={removeStringText(profAdress?.zipcode)} />
                                </div>
                                <div id="googleMap" className="mb-3" key={JSON.stringify(currentCenter) + "map"}>
                                    {konsole.log("vbdkjsb", currentCenter)}
                                    <APIProvider apiKey={mapKey}>
                                        <Map
                                            defaultZoom={12}
                                            mapId={"google-map-id"}
                                            defaultCenter={currentCenter ?? defaultMapCoordinates}
                                        >
                                            {(profData?.proFullName && currentCenter) && <AdvancedMarker
                                                position={currentCenter}
                                                className="markerBox"
                                            ></AdvancedMarker>}
                                        </Map>
                                    </APIProvider>
                                </div>
                            </div>
                        </div>

                </div>
                <div className="col-2 d-none d-md-block"></div>
            </div>
        </div>}
        </>
    )
}

export default ViewProfile;

const SimpleLabelValue = ( props ) => {
    return (
        <div className="me-2 mb-2">
            {(props?.show) ? 
            <>
                <p className="text-secondary m-0">{props?.label || ""}</p>
                <p className={`m-0 ${!props?.value ? 'ps-3' : ''}`}>{props?.value || "-"} {(props?.value && props?.isLink == true) && <a target="_blank" href={commonLib.getWebsiteLink(props?.value)}><img src="/icons/linkOpen.svg" alt="openLinkIcon" /></a>}</p>
            </>
            :   <div className="col-3"><span className="placeholder form-control"></span></div>}
        </div>
    )
}