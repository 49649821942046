import React, { useEffect, useState, useContext, useRef, Suspense, lazy } from "react";
import konsole from "../network/konsole";
import Services, { cancelPrevCancelableInvoke, cancelableInvoke } from "../network/Services";
import Accordion from "react-bootstrap/Accordion";
import "./Heads.css";
// import "./List.css";
import "./Accordian.css";
import { UserContext } from "../App";
// import { LoadScript, LoadScriptNext, MarkerF } from "@react-google-maps/api";
// import { Alert, Checkbox, Input, Space, Switch, message } from "antd";
import { message } from "antd";
// import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import Address from "./Address";
import { defaultMapCoordinates, googleLibraries, mapKey, proSerDescList } from "../control/Constant";
import commonLib from "../control/commonLib";
import Professionalcard from "./Professionalcard";
import AoUrl from "../network/url";
import { APIProvider } from "@vis.gl/react-google-maps";
import { useProSubTypes, useProTypes } from "../reduxStore/profTypesSlice";
import { useSearchState } from "../reduxStore/searchDataSlice";
const GoogleMap  = lazy(() => import("./GoogleMap"));

const Heads = () => {
  const [centerLocation, setcenterLocation] = useSearchState('centerLocation');
  const [proTypeListObj, setproTypeListObj] = useProTypes();
  const [proSubTypeListObj, setproSubTypeListObj] = useProSubTypes();
  const [openedSubType, setopenedSubType] = useState("");
  const [isNotMapView, setisNotMapView] = useState(true);
  const [Hamburgershow, setHamburgershow] = useState(false);
  // const [selectedCenter, setSelectedCenter] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(null);

  // =========== storing selected professional type details ===============
  const [proSerDescIdSelected, setproSerDescIdSelected] = useSearchState('proSerDescIdSelected')
  const [proTypeSelected, setproTypeSelected] = useSearchState('proTypeSelected')
  const [proSubTypeSelected, setproSubTypeSelected] = useSearchState('proSubTypeSelected')
  const [forBoardCertified, setforBoardCertified] = useSearchState('forBoardCertified')

  // =========== storing address details =================================
  const [selectLiveAddress, setselectLiveAddress] = useSearchState('selectLiveAddress');
  const [selectedAddressLineText, setselectedAddressLineText] = useSearchState('selectedAddressLineText');
  const [selectedAddress, setselectedAddress] = useSearchState('selectedAddress');
  const [showAddInput, setshowAddInput] = useState(false)

  // =========== storing search name details ============================
  const [lastSearchText, setlastSearchText] = useSearchState('lastSearchText');
  const [curSearchText, setcurSearchText] = useSearchState('curSearchText');

  // =========== storing search results =================================
  const [currentPageNo, setcurrentPageNo] = useSearchState('currentPageNo');
  const [totalResult, settotalResult] = useSearchState('totalResult');
  const [needSearch, setneedSearch] = useSearchState('needSearch');
  const [allPrevProfMemberUserIds, setallPrevProfMemberUserIds] = useState(null);

  // =========== Other states ==========================================
  const { setLoader } = useContext(UserContext);
  // const accessPrevState = commonLib.GetQueryValues("accessPrevState");
  const tokenKey = commonLib.getSessionStoarge('tokenKey') || "";
  const decodedKey = window.atob(tokenKey);
  const proCategoriesDecoded = commonLib.getSessionStoarge('proCategories') || "";

  // mainly used for initial call only
  useEffect(() => {
    if(!Object.keys(proTypeListObj)?.length) setAllProfTypeDetails()
    initialActions()
  }, [decodedKey, proCategoriesDecoded])

  // mainly used for call searchAPI only
  useEffect(() => {
    if(Object.keys(proTypeListObj)?.length == 5 && Object.keys(proSubTypeListObj)?.length && needSearch) { // this condition is to call search only after protype and prosubtype list fully loaded
      konsole.log("eruiote", proSerDescIdSelected, proTypeSelected, proSubTypeSelected)
      searchForProfessional();
    }
  }, [lastSearchText, selectedAddress, proSerDescIdSelected, proTypeSelected, proSubTypeSelected, proTypeListObj, proSubTypeListObj, forBoardCertified])

  const initialActions = () => {
    const memberUserId = commonLib.GetQueryDecodedValues(decodedKey, "memberUserId") || "";
    const primaryUserId = commonLib.GetQueryDecodedValues(decodedKey, "userId") || "";
    if(memberUserId && primaryUserId) getPrevProffMembers(memberUserId, primaryUserId);

    if(needSearch == false) return;

    const selectedModule = commonLib.GetQueryDecodedValues(decodedKey, "selectedModule") || "";
    const selectedRole = commonLib.GetQueryDecodedValues(decodedKey, "selectedRole") || "";

    const _proSerDescIdSelected = commonLib.GetQueryDecodedValues(proCategoriesDecoded, "proSerDescIdSelected") || "";
    const _proTypeSelected = commonLib.GetQueryDecodedValues(proCategoriesDecoded, "proTypeSelected") || "";
    const _proSubTypeSelected = commonLib.GetQueryDecodedValues(proCategoriesDecoded, "proSubTypeSelected") || "";

    if(_proSerDescIdSelected?.length) { 
      // logic for all project clients except agent module ==============================
      setproSerDescIdSelected(_proSerDescIdSelected?.split(",")?.filter(ele => ele != ""))
    } else if(selectedModule) { 
      // logic for agent module clients =================================================
      const proSerDescValue = commonLib.capitalize(selectedModule);
      const proSerId = proSerDescList?.find(ele => ele.label == proSerDescValue)?.value;
      setproSerDescIdSelected([proSerId])
      if(selectedRole) setproTypeSelected([selectedRole]);
      commonLib.setSessionStoarge('proCategories', "isLPO=true");
    } else {
      // logic for directly using clients =========================================================
      setproSerDescIdSelected(['1']);
    }

    if(_proTypeSelected) setproTypeSelected(_proTypeSelected?.split(",")?.filter(ele => ele != ""));
    if(_proSubTypeSelected) setproSubTypeSelected(_proSubTypeSelected?.split(",")?.filter(ele => ele != ""));
    setselectLiveAddress(true);
  }

  // for proper working of loader
  let loaderCount = 0;
  const setLoaderCount = ( increase ) => {
    loaderCount = increase == true ? loaderCount + 1 : Math.max(loaderCount - 1, 0);
    if(loaderCount == 0) setLoader(false);
    else setLoader(true);
  }

  const getPrevProffMembers = (memberUserId, primaryUserId) => {
    konsole.log(`fetchingPrevProffFor memberid ${memberUserId} and primaryUserId ${primaryUserId}`);
    Services.getMemberProfessionals(memberUserId, primaryUserId)
    .then((res) => {
      const result = res?.data?.data;
      let allProfMemberUserIds = result.length > 0 ? result?.map((proff) => proff.professionalUserId) : [];
      konsole.log("fetchingPrevProffFor", allProfMemberUserIds);
      setallPrevProfMemberUserIds(allProfMemberUserIds);
    })
    .catch((err) => {
      konsole.log("fetchingPrevProffFor Error", err);
      setallPrevProfMemberUserIds([]);
    })
  }

  const disableAddButtonAsPerToken = (professionalUserId) => {
    return allPrevProfMemberUserIds?.every((prevProfUserId) => prevProfUserId != professionalUserId) != true;
  }

  const getProfTypeListOf = ( proserdescId ) => {
    const profTypeList = proTypeListObj?.[proserdescId] || [];
    const proSerDescName = proSerDescList?.find(ele => ele.value == proserdescId)?.label;
    // konsole.log("sbdvhbs", proSerDescName, profTypeList)
    return {proSerDescName, profTypeList};
  }

  const setAllProfTypeDetails = () => {
    proSerDescList.forEach(_proSerDescId => fetchProfessionalTypeListOf(_proSerDescId.value));

    setLoaderCount(true);
    Services.commonAPIInvoke("GET", AoUrl.getProfessionalSubTypeurl)
    .then((res) => {
      setLoaderCount(false);
      const result = res?.data?.data;
      if(result) {
        let listObj = {}
        result?.forEach(ele => {
          ele.proTypeId += "" 
          ele.proSubTypeId += "" 
          if(listObj[ele.proTypeId]) listObj[ele.proTypeId] = [...listObj[ele.proTypeId], ele];
          else listObj[ele.proTypeId] = [ele];
        })

        // sorting ---
        Object.keys(listObj).forEach((key) => {
          listObj[key] = listObj[key]?.sort((a, b) => a.proSubType?.localeCompare(b.proSubType));
        })

        konsole.log("vfhgv", listObj)
        setproSubTypeListObj(listObj)
      } 
    })
    .catch(err => konsole.log("wdbah", err))
  }

  const fetchProfessionalTypeListOf = ( proserdescId ) => {
    setLoaderCount(true);
    Services.commonAPIInvoke("GET", AoUrl.getProfessionalTypeurl + `?proSerDescId=${proserdescId}`, {})
    .then(res => {
      setLoaderCount(false);
      konsole.log("vsbh", res);
      setproTypeListObj({key: proserdescId, value: res.data.data}); // don't give ?. operator here
    })
    .catch(err => {
      konsole.log("vsbh", err);
    })
  }

  const isAnyComEleBtwList = ( list1, list2 ) => { // pass list with same data type only
    if(!list1?.length || !list2?.length) return false;
    
    let newList = [...list1, ...list2];
    let setArr = new Set(newList);
    konsole.log("sbczdasdafsdv", list1, list2, setArr, setArr.size, newList.length);
    if(setArr.size == newList.length) return false;
    return true;
  }

  const AMinuseBSetTo = ( a, b, setStateFunc ) => {
    let setA = new Set(a);
    let setB = new Set(b);

    setStateFunc(Array.from(setA.difference(setB)));
  }

  const onSelectProfType = ( proSerDescId, proTypeId, proSubTypeId ) => {
    if(proSubTypeId) {
      let _setList = new Set(proSubTypeSelected);

      if(_setList.has(proSubTypeId)) _setList.delete(proSubTypeId)
      else {
        _setList.add(proSubTypeId)
        if(proTypeSelected?.includes(proTypeId) == false) onSelectProfType(proSerDescId, proTypeId);
      }

      setproSubTypeSelected(Array.from(_setList));
    }
    else if(proTypeId) {
      let _setList = new Set(proTypeSelected);
      
      if(_setList.has(proTypeId)) {
        _setList.delete(proTypeId)
        const _proSubTypeListObj = proSubTypeListObj[proTypeId]?.map(ele => ele.proSubTypeId)
        AMinuseBSetTo(proSubTypeSelected, _proSubTypeListObj, setproSubTypeSelected)
      }
      else _setList.add(proTypeId)
    
      setproTypeSelected(Array.from(_setList));
    }
    else if(proSerDescId) {
      let _setList = new Set(proSerDescIdSelected);

      if(_setList.has(proSerDescId)) {
        _setList.delete(proSerDescId)
        const _protypeList = proTypeListObj[proSerDescId]?.map(ele => ele.value);
        const _proSubTypeListObj = _protypeList?.map(_proTypeId => proSubTypeListObj[_proTypeId]?.map(ele => ele.proSubTypeId)).flat(1);
        AMinuseBSetTo(proTypeSelected, _protypeList, setproTypeSelected)
        AMinuseBSetTo(proSubTypeSelected, _proSubTypeListObj, setproSubTypeSelected)
      }
      else _setList.add(proSerDescId)

      setproSerDescIdSelected(Array.from(_setList))
    }
  }

  const onSelectAddress = ( addressText, addressObj ) => {
    setselectedAddressLineText(addressText);
    setselectedAddress(addressObj);
    if(addressObj?.coordinates) setcenterLocation(addressObj.coordinates)
    else setcenterLocation(defaultMapCoordinates)

    setlastSearchText(curSearchText);
  }

  const proTypeForSearchGetter = () => {
    let finalList = [...proTypeSelected];
    proSerDescIdSelected.forEach( _id => {
      const allProTypeOf_id = proTypeListObj[_id]?.map(ele => ele.value)
      konsole.log("sbczdv", allProTypeOf_id, proTypeSelected)
      if(commonLib.isNotNullUndefined(allProTypeOf_id) && (isAnyComEleBtwList(allProTypeOf_id, proTypeSelected) == false)) finalList.push(...allProTypeOf_id)
    })
    return finalList.join(',');
  }
  
  const proSubTypeForSearchGetter = () => {
    konsole.log("dabvb", proSubTypeListObj, Object.entries(proSubTypeListObj))
    // if(proSubTypeSelected?.length == 0) return "";

    let finalSubTypesStr = proSubTypeSelected?.join(',');
    for(let [proTypeId, proSubTypes] of Object.entries(proSubTypeListObj)) {
      if(!proTypeSelected?.includes(proTypeId)) continue;
      konsole.log("adbvjb", proTypeId, proSubTypes)
      let isSubTypeIncluded = true;
      for(let ele1 of proSubTypeSelected) {
        isSubTypeIncluded = proSubTypes?.some(ele2 => ele2?.proSubTypeId == ele1);
        if(isSubTypeIncluded) break;
      }
      if(isSubTypeIncluded == false) finalSubTypesStr += "," + proSubTypes?.map(ele => ele?.proSubTypeId)?.join(',');
    }
    konsole.log("bvjhvjh", finalSubTypesStr);
    return finalSubTypesStr;
  }

  const searchForProfessional = ( searchMore ) => {
    // collecting and formating data
    const searchAddressText = selectedAddress ? (selectedAddress.city || selectedAddress.state || selectedAddress.country) : "";
    const searchName = lastSearchText || "";
    const proSerDescForSearch = proSerDescIdSelected?.join(',');
    const proTypeForSearch = proTypeForSearchGetter();
    const proSubTypeForSearch = proSubTypeForSearchGetter();
    const searchForBoardCertified = forBoardCertified == true;
    konsole.log("sbvjhbszj", proTypeForSearch)
    let pageNumber;
    let tempTotalResult = totalResult;

    // Reseting previous results
    cancelPrevCancelableInvoke?.();
    if(searchMore != true) {
      settotalResult({});
      changePageNoTo(0);
      pageNumber = 1;
      tempTotalResult = {};
    } else {
      pageNumber = currentPageNo + 1;
      if(totalResult[pageNumber]?.length > 0) {
        return changePageNoTo(currentPageNo + 1)
      }
    }

    if((!searchAddressText && !searchName) || !proSerDescForSearch) {
      if(selectLiveAddress == true) return;
        message.warning("Please enter location or name to search")
      return
    }

    setselectLiveAddress(false);

    // let APIFinalURL = AoUrl.getProfessSearchV3 + "?";
    // if(searchAddressText) APIFinalURL += `SearchText=${searchAddressText}&`;
    // if(searchName) APIFinalURL += `SearchName=${searchName}&`;
    // if(proSerDescForSearch) APIFinalURL += `ProSerDescIds=${proSerDescForSearch}&`;
    // if(proTypeForSearch) APIFinalURL += `ProTypeIds=${proTypeForSearch}&`;
    // if(proSubTypeForSearch) APIFinalURL += `ProSubTypeIds=${proSubTypeForSearch}&`;
    // APIFinalURL += `PageNumber=${pageNumber}&`;
    // APIFinalURL += `RowsOfPage=${10}&`;
    const reqObj = {
      SearchText: searchAddressText ? searchAddressText : "",
      SearchName: searchName ? searchName : "",
      ProSerDescIds: proSerDescForSearch ? proSerDescForSearch : "",
      ProTypeIds: proTypeForSearch ? proTypeForSearch : "",
      ProSubTypeIds: proSubTypeForSearch ? proSubTypeForSearch : "",
      PageNumber: pageNumber,
      RowsOfPage: 10,
      board: searchForBoardCertified,
    }

    setLoaderCount(true)
    // cancelableInvoke(APIFinalURL, "GET", {})
    cancelableInvoke(AoUrl.postGetProfessSearchV3, "POST", reqObj)
    .then(res => {
      const response = res?.data?.data;
      settotalResult({...tempTotalResult, [pageNumber]: response});
      changePageNoTo(pageNumber);
      konsole.log("sbdchjbs", response, tempTotalResult, pageNumber)
    })
    .catch(error => {
      settotalResult({...tempTotalResult, [pageNumber]: "end"});
      konsole.log("sbdchjbs", error)
    }).finally(() => setLoaderCount(false))
  }

  const changePageNoTo = ( pageNo ) => {
    setcurrentPageNo(pageNo);
    // if(isNotMapView == true) window.scrollTo(0, 1);
    if(isNotMapView == true && pageNo >= 2) var timeoutId = setTimeout(() => {document.getElementById("maindiv").scrollIntoView({behavior: "smooth"}); clearTimeout(timeoutId);}, [300]);
  }

  const getSingleProfessionalUserToList = ( profObjList ) => {
    const mapSelectedProUserIds = profObjList?.map(ele => ele.proUserId);
    let newPageData = totalResult[currentPageNo]?.filter(ele => mapSelectedProUserIds?.includes(ele.proUserId) == false);
    settotalResult({...totalResult, [currentPageNo]: [...profObjList, ...newPageData]});
    setisNotMapView(true);
  }

  // ============= professional type check box filter ================
  const ProfCatFilter = () => {
    const _proSerDescIdSelected = commonLib.softCopyObj(proSerDescIdSelected);
    return <>
    {_proSerDescIdSelected?.sort((a, b) => a - b)?.map(_proSerDescId => {// each selected proSerDescIds
      const  {proSerDescName: _proSerDescName, profTypeList: _proTypeList} = getProfTypeListOf(_proSerDescId);
      // konsole.log("sfvbhjbsv",_proSerDescId,_proSerDescName,  _proTypeList);

      return <>
      <h2 key={_proSerDescName}>{_proSerDescName}</h2>
      {_proTypeList?.map(_proTypeObj => {
        return <>

        {/* professional type checkbox */}
        <label key={_proTypeObj.label}
          className="form-check-label cursor-pointer  font-size-health mb-2 w-100"
        >
          <input 
            className="form-check-input "
            type="checkBox" 
            key={_proTypeObj.value}
            checked={proTypeSelected?.includes(_proTypeObj.value)}
            onChange={() => onSelectProfType(_proSerDescId, _proTypeObj.value)} 
          />
          {" "}
          {proSubTypeListObj[_proTypeObj.value]?.length && <img
            className={
              openedSubType == _proTypeObj.value
              ? "rotate90"
              : "rotate0"
            }
            src="./images/dropdown.png"
            onClick={() => {setopenedSubType(openedSubType == _proTypeObj.value ? "" : _proTypeObj.value)}}
          />}
          {" "}{_proTypeObj.label}
        </label>
        {/* ======================== */}

        {/* professional subtype checkbox */}
        {openedSubType == _proTypeObj.value && <div className="ms-1 mb-2 ps-2" style={{fontSize:"14px", borderLeft: '2px solid var(--grey)'}}>
          {proSubTypeListObj[_proTypeObj.value]?.map(_proSubTypeObj => {
            return <>
              <label
                className="form-check-label w-100 font-size-health mb-2"
                key={_proSubTypeObj.proSubTypeId}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={_proSubTypeObj.proSubTypeId}
                  checked={proSubTypeSelected?.includes(_proSubTypeObj.proSubTypeId)}
                  onChange={() => onSelectProfType(_proSerDescId, _proTypeObj.value, _proSubTypeObj.proSubTypeId)}
                />{" "}
                {_proSubTypeObj.proSubType}
              </label>
            </>
          })}
        </div>}
        {/* ======================== */}

        </>
      })}
      </>
    })}
    </>
  }
  // =============================================================

  konsole.log("rendered_nbkjed")

  return (<>
    <div
      className="main-div"
      style={{
        top: "0",
        background: "white",
        zIndex: "1",
        justifyContent: "center",
        paddingBottom: '16px'
      }}
    >
      <div className="container" style={{padding: '16px'}}>
        <div className="row">
          <div className="col-md-12" style={{zIndex:0}}>
            <h1 style={{ color: "#720C20"}} className=" text-center">
              Resource Guide
            </h1>
          </div>
          <div className="col-md-12 mb-2 d-flex  image-div  justify-content-around">
            {proSerDescList?.map((proSerDescObj) => {
              const {value, label, unSelectedImg, selectedImg} = proSerDescObj;
              const imgSrc = proSerDescIdSelected?.includes(value) ? selectedImg : unSelectedImg;

              return (
                <img
                  style={{cursor: 'pointer'}}
                  key={label}
                  src={imgSrc}
                  id={label}
                  alt={label}
                  value={value}
                  onClick={() => onSelectProfType(value)}
                />
              )
            })}
          </div>
          <div className="col-md-12 col-sm-12  heads3 ">
            <h5 className="text-center  text-head">
              Find professional service providers
            </h5>
            <div
              className="d-flex  justify-content-center flex-wrap flex-md-nowrap "
              style={{ margin: "6px" }}
            >
              <div
                className=" col-md-4  col-sm-12  cross-icon-img  col-12 "
                style={{ border: "1px solid lightgrey", margin: "5px 5px" }}
              >
                <div className=" d-flex location-searchbar-res align-self-center col-12  pe-md-3 pe-1 ">
                  <img
                    src="\images\carbon_location.svg"
                    className=" ms-md-1"
                  />
                  {/* <LoadScriptNext
                    googleMapsApiKey={mapKey}
                    libraries={googleLibraries}
                  > */}
                  <APIProvider apiKey={mapKey} libraries={googleLibraries} onLoad={() => setshowAddInput(true)} >
                    {showAddInput == true && <Address
                      Addresfieldnull={() => onSelectAddress("", {})}
                      onFetchAddress={onSelectAddress}
                      currentAddress={selectedAddressLineText}
                      getCurrentLocOnStart={selectLiveAddress}
                    />}
                  </APIProvider>
                  {/* </LoadScriptNext> */}
                </div>
              </div>
              <div
                className="col-md-6 col-sm-12 col-12 pd-2 ps-2 rounded-end"
                style={{ border: "1px solid lightgrey", margin: "5px 0px" }}
              >
                <div className="d-flex col-md-12 col-sm-12 h-100">
                  <input
                    type="text"
                    className="w-100 rounded-end  professionals-search-outline"
                    style={{ border: "none" }}
                    placeholder="Search for the professionals by name or categories"
                    onChange={(e) => setcurSearchText(e.target.value)}
                    value={curSearchText}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setlastSearchText(e.target.value);
                      }
                    }}
                  />
                  <button
                    style={{
                      background: "#EFEFEF",
                      borderRadius: "4px",
                      width: "50px",
                      border: "none",
                    }}
                    onClick={() => {
                      setlastSearchText(curSearchText);
                    }}
                    className="d-flex py-2 py-lg-0 fs-5 fs-md-3"
                  >
                    <img
                      src="/images/fe_search (3).png"
                      style={{
                        width: "25px",
                        height: "auto",
                        margin: "auto",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div
          className=" bottom m-0 d-flex justify-content-between align-items-center pe-2 ps-2"
          style={{ width: "90%" }}
        >
          <img
            src="\images\Rectangle.png"
            className="img-fluid"
            style={{
              height: "20px",
            }}
          ></img>
          <p className="tear  fs-md-2 Tear-down-small d-md-block mt-2">
            <a
              className="tear  fs-md-2 Tear-down-small d-md-block "
              href="#maindiv"
            >
              {" "}
              {/* Tear-down Page */}
              Tear out sheet
            </a>
          </p>
        </div>
      </div>
    </div>

    <div className="container-fluid pb-3" id="maindiv">
      <div className="row-12 d-flex mx-0  "
          style={{ justifyContent: "center" }}
      >
        <div
          className="col-md-8 col-lg-8 col-12 px-0 px-0 float-right"
        >
          <div className=" d-flex  align-items-center col-md-8 col-md-12  mb-md-2 mt-md-3 m-1">
            <div className="d-flex map-viws-work">
              <label
                className="form-check-label map-list me-2"
                htmlFor="flexSwitchCheckDefault"
              >
                Map View
              </label>
              <div className="margin-fixed-doogle form-check form-switch">
                <input
                  className="form-check-input toggle shadow-none"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={isNotMapView}
                  onChange={(e) => {
                    setisNotMapView(e.target.checked == true);
                    konsole.log("sbvhjbs", e.target.checked == true)
                  }}
                  style={{ maxWidth: "50px" }}
                />
                <label
                  className="form-check-label ms-2  map-list"
                  htmlFor="List View"
                >
                  List View
                </label>
              </div>
            </div>
            <div className="me-3 ms-auto">
              <input
                className="form-check-input ms-1 mt-2"
                type="checkbox"
                id=""
                checked={forBoardCertified}
                onChange={(e) => {
                  setforBoardCertified(e.target.checked == true);
                }}
                style={{ width: "18px", height: "18px"}}
              />
                <label
                  className="form-check-label ms-1 mt-1 map-list"
                  htmlFor="Board Certified"
                >
                  Board Certified
                </label>
            </div>
            <div className="position-relative show_hamburger_mob">
              <img
                style={{ height: "25px", cursor: 'pointer' }}
                src="images/hambarger.png"
                onClick={() => {
                  setHamburgershow(true);
                }}
              />
              {Hamburgershow == true && (
                <div
                  className="bg-white position-absolute end-0 top-0 p-0 rounded-start rounded-bottom"
                  style={{ zIndex: "1000", width: "250px", fontSize: "14px" }}
                >
                  <div
                    className="bg-white float-end rounded-end border-0"
                    style={{ zIndex: "2", cursor: "pointer" }}
                    onClick={() => {
                      setHamburgershow(false);
                    }}
                  >
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src="/images/closebutton.png"
                    />
                  </div>
                  <div className="p-2 vertical-scroll">
                    <ProfCatFilter />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <LoadScriptNext googleMapsApiKey={mapKey} libraries={googleLibraries}> */}
          {isNotMapView == true ? 
          (<div>
            {(!totalResult[currentPageNo]?.length) && <h4 className="text-center m-2 mt-5">
              No professionals available
            </h4>}
            {totalResult[currentPageNo]?.map(profObj => {
              return <Professionalcard item={commonLib.softCopyObj(profObj)} tokenKey={tokenKey} disableBtn={disableAddButtonAsPerToken(profObj?.userId)} key={profObj?.proUserId}/>
            })}
          </div>) : (
            <Suspense fallback={() => <p>Loading Map...</p>}>
              <GoogleMap 
                professionals={totalResult[currentPageNo]} 
                setSelectedProf={getSingleProfessionalUserToList}
                style={{
                  maxWidth: "109rem",
                  width: "100%",
                  height: "37rem",
                  top: "20px",
                }}
                center={centerLocation}
                zoom={selectedAddress?.city ? 12 : selectedAddress?.state ? 8 : selectedAddress?.country ? 4 : 12}  
                />
            </Suspense>
          )}

          {/* show more / show less */}
          {(currentPageNo > 1 || (totalResult[currentPageNo] && totalResult[currentPageNo + 1] != "end")) && <div className="row me-2 mt-4 mb-2">
            <div className="col-6  col-lg-6  ">
              {" "}
              {currentPageNo > 1 && (
                <button
                  className="show_less ms-2"
                  style={{ width: "7rem" }}
                  onClick={() => changePageNoTo(currentPageNo - 1)}
                >
                  Show Less
              </button>
              )}
            </div>
            <div className="col-6 col-lg-6 m-0 p-0 d-flex justify-content-end">
              {totalResult[currentPageNo] && totalResult[currentPageNo + 1] != "end" && (
                  <button
                    className="show_more"
                    style={{ width: "7rem" }}
                    onClick={() => searchForProfessional(true)}
                  >
                    Show More
                  </button>
                )}
            </div>
          </div>}
          {/* ========= */}
          {/* </LoadScriptNext> */}
        </div>
        <div className="col-md-3  col-lg-3 mt-3 ms-2 professinal-text-size" >
          <Accordion
            className="accord mt-1 collapse show professinal-text-size"
            defaultActiveKey="1"
          >
            <Accordion.Item
              eventKey="1"
              className="accordion-collapse collapse professinal-text-size show panel-collapse collapse in"
            >
              <Accordion.Header className="filterHeader">Filters</Accordion.Header>
              <Accordion.Body className="accordion-collapse collapse show in vertical-scroll">
                <ProfCatFilter />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  </>)
}

export default Heads;
